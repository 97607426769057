@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.language-switcher {
  position: absolute;
  list-style: none;
  margin-left: 20px;
  z-index: 100;
  margin-right: 50px;
  right: 5%;
}

.header__logo {
  height: 70px;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  z-index: 102;
}

@media screen and (max-width: 620px) {
  .header__logo {
    max-width: 150px;
  }
}

.slick-dots li {
  width: 70px !important;
  height: 4px !important;
  background: #6beef1;
  opacity: 0.5;
}

.slick-dots li.slick-active {
  background: #6beef1;
  opacity: 1;
}

.custom-next-arrow,
.custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999;
}

.custom-next-arrow {
  right: -25px;
}

.custom-prev-arrow {
  left: -25px;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1199.98px) {
  .custom-next-arrow {
    right: 25px;
  }

  .custom-prev-arrow {
    left: 25px;
  }

}

.header-slider-menu {
  display: block;
  list-style: none;
  padding: 60px 120px;
  width: 100%;
}

.header-slider-menu-link {
  margin-bottom: 16px;
}

.header-slider-menu-link:last-child {
  margin-bottom: 52px;
}

.header-slider-menu-link__active {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 48px;
  color: #fff;
  cursor: pointer;
}

.header-slider-menu-link__active:hover {
  color: #2f56e8;
}

.header-slider-menu-link__active:hover svg {
  fill: #2f56e8;
}

.header-slider-menu-link__active.--active svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header-slider-menu-link__active svg {
  width: 27px;
  fill: #fff;
  margin-left: 15px;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  -o-transition: transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.header-slider-menu-link a {
  width: 100%;
  font-weight: 700;
  font-size: 48px;
  color: #fff;
}

.header-slider-menu-link a:hover {
  color: #2f56e8;
  text-decoration: underline;
}

.header-slider-menu-link ul {
  list-style: none;
  padding: 0 45px;
  padding-bottom: 0;
}

.header-slider-menu-link ul li {
  padding: 3px 0;
}

.header-slider-menu-link ul li a {
  font-size: 24px;
}

.header-slider-menu-link ul li a:hover {
  color: #2f56e8;
}

@media screen and (max-width: 1440px) {
  .header-slider-menu {
    padding: 60px 120px;
  }

  .header-slider-menu-link__active {
    font-size: 34px;
  }

  .header-slider-menu-link svg {
    width: 22px;
    margin-left: 12px;
  }

  .header-slider-menu-link a {
    font-size: 34px;
  }

  .header-slider-menu-link ul {
    padding: 0 30px;
  }

  .header-slider-menu-link ul li a {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header-slider-menu {
    padding: 140px 20px;
  }

  .header-slider-menu-link__active {
    font-size: 24px;
  }

  .header-slider-menu-link svg {
    width: 18px;
    margin-left: 10px;
  }

  .header-slider-menu-link a {
    font-size: 24px;
  }

  .header-slider-menu-link ul {
    padding: 0 20px;
  }

  .header-slider-menu-link ul li a {
    font-size: 16px;
  }
}

img{
  transition: .2s;
}
.bgHover:hover img{
  opacity: .5;
}


.news-header .main-header {
  min-height: 66vh !important;
  height: 66vh;
}

.react-responsive-modal-root{
  z-index: 99999 !important;
}